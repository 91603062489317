@import url('https://rsms.me/inter/inter.css');
@font-face {
  font-family: 'Inconsolata';
  src: local('Inconsolata'), url(./assets/inconsolata-variable.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background: url('./assets/background.jpg');
  background-size: cover;
}

body {
  overscroll-behavior-y: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}


a {
  pointer-events: all;
  text-decoration: none;
}

.overlay{
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logoContainer{
  position: absolute;
  top: 80px;
  left: 80px;
}

.logoContainer img{
  width: 250px;
}

.socialContainer {
  position: absolute;
  top: 80px;
  right: 80px;
}

.sloganContainer{
  position: absolute;
  bottom: 80px;
  right: 80px;
  max-width: 500px;
}

.slogan {
  color: #FFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: Inconsolata;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  flex-shrink: 0;
  text-align: justify;
}

@media (width > 1920px) {
 
.logoContainer{
  top: 124px;
  left: 140px;
}

.logoContainer img{
  width: auto;
}

.socialContainer {
  top: 124px;
  right: 140px;
}

.slogan {
  font-size: 40px;
}

  .sloganContainer{
    max-width: 600px;
    right: 140px;
  }
}


@media (width < 1921px) {
 
  .logoContainer img{
    width: 180px;
  }
  .socialContainer img {
    width: 40px;
  }
  .slogan {
    font-size: 25px;
  }
}

@media (width < 1440px){ 

  
  .socialContainer {
    top: 40px;
    right: 40px;
  }
  .sloganContainer{
    bottom: 40px;
  }
  .logoContainer{
    top: 40px;
    left: 40px;
  }
  
  .sloganContainer{
    bottom: 40px;
    right: auto;
    max-width: 100%;
    width: 100%;
  }
  .slogan {
    text-align: center;
  }
}
@media (width < 500px) {
  
  .logoContainer{
    top: 20px;
    left: 20px;
  }
  
  .logoContainer img{
    width: 110px;
  }
  
  .socialContainer {
    top: 20px;
    right: 20px;
  }
  .socialContainer img {
    width: 25px;
  }
  .socialContainer a{
    margin-right: 15px;
  }
  .slogan {
    font-size: 20px;
    padding: 40px;
  }
}
